import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@zydalabs/zac-react';

import { Text } from 'components/service';
import { ORDER_STATUS } from 'constants/order';
import * as translations from 'constants/translations';

const PaymentStatusOrder = ({ paymentStatus }) => {
  switch (paymentStatus) {
    case ORDER_STATUS.WAITING_FOR_PAYMENT:
    case ORDER_STATUS.IFRAME_URL:
    case ORDER_STATUS.REDIRECT_URL:
      return (
        <Tag
          color="red"
          inner={{
            text: <Text value={translations.WAITING_FOR_PAYMENT_ORDERS} />,
          }}
          size="small"
        />
      );
    case ORDER_STATUS.PAYMENT_FAILED:
    case ORDER_STATUS.PAYMENT_EXPIRED:
      return (
        <Tag
          color="red"
          inner={{
            text: <Text value={translations.PAYMENT_FAILED_ORDER} />,
          }}
          size="small"
        />
      );
    case ORDER_STATUS.PAID:
      return (
        <Tag
          color="success"
          inner={{
            text: <Text value={translations.PAYMENT_PAID_ORDERS} />,
          }}
          size="small"
        />
      );
    default:
      return '';
  }
};

PaymentStatusOrder.propTypes = {
  paymentStatus: PropTypes.oneOf([
    ORDER_STATUS.WAITING_FOR_PAYMENT,
    ORDER_STATUS.REDIRECT_URL,
    ORDER_STATUS.PAYMENT_FAILED,
    ORDER_STATUS.PAYMENT_EXPIRED,
    ORDER_STATUS.PAID,
  ]),
};
export default PaymentStatusOrder;
