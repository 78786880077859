import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@zydalabs/zac-react';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';

const StoreRider = ({
  rider,
  verd,
  assignedDriverId,
  onToggle,
  isCourierNotDeclinedTheRequest,
  openOrderDeliveryConfirmationPopUp,
  handleUnassignDriverToOrder,
  handleAssignDriverToOrder,
}) => {
  const { lang, translate } = useContext(localeContext);
  const riderHasFullCapacity = rider.assignedOrdersCount === verd?.driverMaxCapacity;

  return (
    <div
      className={cx('p-2 w-100 flex flex-1 border-b justify-between', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}
    >
      <span className="flex flex-col items-start" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
        <span className="text-sm text-gray-700">{rider.name}</span>
        <div>
          <Text
            className={cx('text-xs inline-block w-auto', riderHasFullCapacity ? 'text-red-600' : 'text-green-600')}
            value={riderHasFullCapacity ? translations.MAX_CAPACITY : translations.AVAILABLE}
          />
          {rider.onShift && rider.assignedOrdersCount > 0 && (
            <>
              <Text className="text-xs text-gray-400 inline-block w-auto mx-1" value={translations.UNDERSCORE} />
              <Text
                className="text-xs text-gray-400 inline-block w-auto"
                value={translations.ASSIGNED_ORDERS}
                payload={rider.assignedOrdersCount}
              />
            </>
          )}
        </div>
      </span>
      <div>
        {rider?.id === assignedDriverId ? (
          <Button
            text={translate(translations.UNASSIGN)}
            size="small"
            variant="tertiary"
            onClick={() => {
              onToggle();
              handleUnassignDriverToOrder();
            }}
          />
        ) : (
          <Button
            text={translate(translations.ASSIGN)}
            size="small"
            variant={riderHasFullCapacity ? 'primary' : 'tertiary'}
            disabled={riderHasFullCapacity}
            onClick={() => {
              onToggle();
              isCourierNotDeclinedTheRequest
                ? openOrderDeliveryConfirmationPopUp({
                    assignDriverToOrder: handleAssignDriverToOrder,
                    assignee: rider,
                  })
                : handleAssignDriverToOrder(rider);
            }}
          />
        )}
      </div>
    </div>
  );
};

StoreRider.propTypes = {
  rider: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  verd: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    displayNameEn: PropTypes.string,
    displayNameAr: PropTypes.string,
  }),
  assignedDriverId: PropTypes.number,
  isCourierNotDeclinedTheRequest: PropTypes.bool,
  onToggle: PropTypes.func,
  openOrderDeliveryConfirmationPopUp: PropTypes.func,
  handleUnassignDriverToOrder: PropTypes.func,
  handleAssignDriverToOrder: PropTypes.func,
};

export default StoreRider;
