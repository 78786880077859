import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import moment from 'moment';
import { Typography, useTheme } from '@zydalabs/zac-react';

import { DropDown, SpinnerAlt } from 'components/kit';
import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { ORDER_STATUS } from 'constants/order';
import OrderDeliveryConfirmation from 'components/common/orders/OrderDeliveryConfirmation';
import { camelCaseKeys } from 'pages/setup/paymentMethods/PaymentGateway/utils';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { useSelectedStore, useDeliveryZoneMsToken } from 'hooks';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { useFetchVerdDrivers, useFetchCouriersEstimations } from 'service/hooks';
import * as schemas from '../../../schemas';
import { DELIVERY_ENUMS } from '../../../constants';
import StoreRider from './StoreRider';
import StoreCourier from './StoreCourier';
import StoreRiderInfo from './StoreRiderInfo';
import StoreCourierInfo from './StoreCourierInfo';
import useHandleAssignUnAssignDriverToOrder from './handleAssignDrivertoOrder';
import useHandleAssignUnAssignCourierToOrder from './handleAssignCourierToOrder';
import RestaurantNotHasRiders from './restaurantNotHasRiders';

export const OnFleetDeliveryActionButtons = ({ order, updatedCourierId, sendChangeStatus, open, close }) => {
  const { status: orderStatus, branchData } = order || {};
  const { lang, direction } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const { colors } = useTheme();
  const isArabic = lang === 'ar';
  const {
    selectedStore: { restaurantCourierSetting, currency, timeZone },
    settings,
  } = useContext(userContext);
  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);
  const isVerdEnabled = settings?.enableVerd;
  const filteredRestaurantCourierSetting = restaurantCourierSetting.filter(
    courier => !courier.isInternalDelivery && courier.businessType === THIRD_PARTY_TYPE_ENUM.Delivery,
  );
  const deliveryZoneMsToken = useDeliveryZoneMsToken();

  const { data: activeRiders, mutate: mutateFetchVerdDrivers } = useFetchVerdDrivers({
    storeId: selectedStoreId,
    branchId: parseInt(branchData.id),
  });
  const { data: couriersEstimations } = useFetchCouriersEstimations({
    number: order.number,
  });

  const { data: storeCouriersList } = useQuery(schemas.LIST_COURIERS, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    variables: {
      businessType: DELIVERY_ENUMS.DELIVERY,
    },
    skip: filteredRestaurantCourierSetting.length === 0,
  });

  // variables
  const orderDeliveryCourierId = order?.deliveryCourier?.courierId;
  const orderDeliveryPhoneNumber = order?.deliveryCourier?.driverPhoneNumber;
  const couriersWithEstimations =
    couriersEstimations && JSON.parse(couriersEstimations)?.map(courier => camelCaseKeys(courier));
  const couriersWithEstimationsIds = couriersWithEstimations?.map(courier => courier.courierId);
  const quickAssignCourierId = order?.deliveryCourierId;
  const isOrderDeliveryCourierNotVerd = quickAssignCourierId !== Number(verd?.courierId);
  const trackingLink = order?.deliveryCourier?.trackingLink;
  const deliveryOrderStatus = order?.deliveryStatus;
  const isScheduledOrder = order?.isScheduled;
  const firingTime = order?.firingTime;
  const referenceId = order?.deliveryCourier?.referenceId;
  const isInternalDelivery = order?.deliveryCourier?.isInternalDelivery;
  const courierDriverName = order?.deliveryCourier?.driverName;
  const hasCourierDriverInfo = order?.deliveryCourier?.hasDriverInfo;
  const courierDriverAssigned = order?.deliveryCourier?.driverAssigned;
  const riders = activeRiders?.length ? activeRiders.filter(rider => rider.onShift) : [];
  const numberOfAvaliableRiders = riders?.filter(rider => rider.assignedOrdersCount !== verd?.driverMaxCapacity)
    ?.length;
  const dropDownPlaceHolder =
    !isVerdEnabled && courierDriverName ? courierDriverName : <Text value={translations.SELECT_RIDER} />;
  const hasRiderInfo = orderDeliveryPhoneNumber && isInternalDelivery;
  const hasCourierInfo = orderDeliveryCourierId && isOrderDeliveryCourierNotVerd;
  const isCourierNotDeclinedTheRequest = hasCourierInfo && deliveryOrderStatus !== ORDER_RIDER_STATUS.DECLINED;
  const restaurantCouriersIds = storeCouriersList?.couriers.map(courier => courier.id);
  const numberOfCouriers = filteredRestaurantCourierSetting?.length;
  const isOrderCancelled = orderStatus === ORDER_STATUS.CANCELED;
  const isOrderDelivered = orderStatus === ORDER_STATUS.DELIVERED;
  const isSubmittedOrder = orderStatus === ORDER_STATUS.SUBMITTED;
  const restaurantCouriersWithLogos = filteredRestaurantCourierSetting?.map(courier => {
    const normalizingCourierId = Number(courier.courierId);
    let courierLogo;
    let courierEstimations;
    if (restaurantCouriersIds?.includes(normalizingCourierId)) {
      const matchedCourier = storeCouriersList?.couriers.find(
        targetCourier => targetCourier.id === normalizingCourierId,
      );
      courierLogo = matchedCourier?.logoUrl;
    }
    if (couriersWithEstimationsIds?.includes(normalizingCourierId)) {
      const matchedCourier = couriersWithEstimations?.find(
        targetCourier => targetCourier.courierId === normalizingCourierId,
      );
      courierEstimations = matchedCourier;
    }
    return { ...courier, courierEstimations, logo: courierLogo };
  });
  const assignedDriverId = activeRiders?.find(driver => driver.phone === orderDeliveryPhoneNumber)?.id;
  const assignedCourierId = Number(
    filteredRestaurantCourierSetting?.find(courier => Number(courier.courierId) === orderDeliveryCourierId)?.courierId,
  );

  const hasCourier = order?.deliveryCourier && updatedCourierId;
  const isDeliveryOrderStatusCancelledOrDeclined =
    deliveryOrderStatus === ORDER_RIDER_STATUS.CANCELED || deliveryOrderStatus === ORDER_RIDER_STATUS.DECLINED;
  const isOrderDeliveredOrCancelled = orderStatus === ORDER_STATUS.DELIVERED || orderStatus === ORDER_STATUS.CANCELED;

  const showDeliveryExpectedAt =
    !isDeliveryOrderStatusCancelledOrDeclined && !isOrderDeliveredOrCancelled && hasCourier;

  const {
    handleAssignCourierToOrder,
    handleUnAssignCourierToOrder,
    isCourierInfoLoading,
  } = useHandleAssignUnAssignCourierToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const openOrderDeliveryConfirmationPopUp = ({
    assignDriverToOrder,
    assignee,
    isAssigneeSupportCancellation,
    isUnAssignCourier,
    acceptOrder,
  }) =>
    open({
      title: <Text value={translations.CONFIRM_CANCELLATION} className="text-xl" />,
      body: (
        <OrderDeliveryConfirmation
          order={order}
          assignDriverToOrder={assignDriverToOrder}
          updatedCourierId={updatedCourierId}
          acceptOrder={acceptOrder}
          sendChangeStatus={sendChangeStatus}
          onCancel={close}
          isUnAssignCourier={isUnAssignCourier}
          isAssigneeSupportCancellation={isAssigneeSupportCancellation}
          assignee={assignee}
        />
      ),
      size: 'max-w-xl',
    });

  const {
    handleAssignDriverToOrder,
    handleUnassignDriverToOrder,
    isRiderInfoLoading,
  } = useHandleAssignUnAssignDriverToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const ridersList = riders?.map(rider => ({
    id: rider.id,
    title: rider.name,
    body: onToggle => (
      <StoreRider
        rider={rider}
        verd={verd}
        isCourierNotDeclinedTheRequest={isCourierNotDeclinedTheRequest}
        openOrderDeliveryConfirmationPopUp={openOrderDeliveryConfirmationPopUp}
        assignedDriverId={assignedDriverId}
        onToggle={onToggle}
        handleAssignDriverToOrder={handleAssignDriverToOrder}
        handleUnassignDriverToOrder={handleUnassignDriverToOrder}
      />
    ),
  }));

  const couriersList = restaurantCouriersWithLogos?.map(courier => ({
    id: Number(courier.courierId),
    title: courier.courierDetails[isArabic ? 'displayNameAr' : 'displayNameEn'],
    body: onToggle => (
      <StoreCourier
        order={order}
        courier={courier}
        openOrderDeliveryConfirmationPopUp={openOrderDeliveryConfirmationPopUp}
        onToggle={onToggle}
        currency={currency}
        isCourierNotDeclinedTheRequest={isCourierNotDeclinedTheRequest}
        assignedCourierId={assignedCourierId}
        quickAssignCourierId={quickAssignCourierId}
        handleAssignCourierToOrder={handleAssignCourierToOrder}
        handleUnAssignCourierToOrder={handleUnAssignCourierToOrder}
      />
    ),
  }));

  const loadingIcon = (
    <div className="w-full flex justify-center mt-4">
      <span className={cx('w-4 h-4 ', direction === 'rtl' ? 'ml-2' : 'mr-2')}>
        <SpinnerAlt color="primary-base" />
      </span>
    </div>
  );

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col border-b ">
        {(riders?.length !== 0 || restaurantCouriersWithLogos?.length !== 0) && !isOrderCancelled && (
          <>
            <DropDown
              isDisabled={isOrderDelivered}
              testId_openDropDown="riders-open"
              testId_selectedData="riders-data"
              testId_listData="riders-list"
              float="left"
              firstTitle={
                isVerdEnabled && (
                  <Typography variant="heading14" color={colors.gray[600]}>
                    <Text value={translations.ACTIVE_RIDERS} payload={numberOfAvaliableRiders} />
                  </Typography>
                )
              }
              secondTitle={
                filteredRestaurantCourierSetting.length > 0 && (
                  <Typography variant="heading14" color={colors.gray[600]}>
                    <Text value={translations.ACTIVE_COURIERS} payload={numberOfCouriers} />
                  </Typography>
                )
              }
              placeholder={dropDownPlaceHolder}
              optionSelected={
                assignedDriverId ||
                assignedCourierId ||
                (isSubmittedOrder && isOrderDeliveryCourierNotVerd && quickAssignCourierId)
              }
              data={ridersList || []}
              extraOptions={couriersList || []}
              icon="keyboard_arrow_down"
              position={lang === 'ar' ? 'right' : 'left'}
              scrollable
              wFull
              full
              noHover
              isDropUp
              isMultipleGroupOfOptions
              width="w-full"
              responsiveLabel
              labelClassNames="text-gray-700"
              noCloseOnSelect
              containerStyle={{
                backgroundColor: 'white',
                marginBottom: '20px',
                opacity: (isRiderInfoLoading || isCourierInfoLoading) && '.4',
              }}
              listContainerStyle={{
                height: ridersList.length > 2 ? '300px' : couriersList.length > 2 ? '240px' : '190px',
              }}
            />
          </>
        )}

        {isRiderInfoLoading
          ? loadingIcon
          : hasRiderInfo && (
              <StoreRiderInfo
                deliveryOrderStatus={deliveryOrderStatus}
                orderDeliveryPhoneNumber={orderDeliveryPhoneNumber}
              />
            )}
        {isCourierInfoLoading
          ? loadingIcon
          : hasCourierInfo && (
              <StoreCourierInfo
                deliveryOrderStatus={deliveryOrderStatus}
                referenceId={referenceId}
                hasDriverInfo={hasCourierDriverInfo}
                driverAssigned={courierDriverAssigned}
                orderDeliveryPhoneNumber={orderDeliveryPhoneNumber}
                driverName={courierDriverName}
                trackingLink={trackingLink}
                isScheduledOrder={isScheduledOrder}
                firingTime={firingTime}
              />
            )}
        {riders?.length === 0 && !hasCourierInfo && (
          <RestaurantNotHasRiders
            mutateFetchVerdDrivers={mutateFetchVerdDrivers}
            sendChangeStatus={sendChangeStatus}
            handleAssignDriverToOrder={handleAssignDriverToOrder}
          />
        )}
      </div>
      {showDeliveryExpectedAt && (
        <div className="mt-3">
          <span>
            <Text className="text-sm font-bold" value={translations.EXPECTED_DELIVERY_TIME} />
          </span>
          <span className="text-sm">
            {moment(order?.expectedAt)
              .tz(timeZone)
              .format('MMMM DD, YYYY - h:mm a')}
          </span>
        </div>
      )}
    </div>
  );
};

OnFleetDeliveryActionButtons.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    status: PropTypes.string.isRequired,
    deliveryTime: PropTypes.number,
    branchData: PropTypes.shape({ id: PropTypes.string }),
    restaurantRiderBranchOrderData: PropTypes.shape({ restaurantRider: PropTypes.string, status: PropTypes.string }),
  }),
};
export default OnFleetDeliveryActionButtons;
