import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { PhoneCallIcon, UserIcon } from '@zydalabs/zac-icons-react';

const OrderDetailsUserContact = ({ order }) => {
  const [phoneCopied, setPhoneCopied] = useState(false);
  const { lang, direction } = useContext(localeContext);
  const { userData } = order || {};
  const { phoneNumber, email } = userData || {};

  useEffect(() => {
    phoneCopied && setTimeout(() => setPhoneCopied(false), 3000);
  }, [phoneCopied]);

  return (
    <div className="w-full flex items-center justify-between border-t border-b border-gray-300 py-4 mt-2">
      <span className={cx('flex items-center text-sm py-1', lang === 'ar' && 'text-right')}>
        <UserIcon width="25px" height="25px" className={cx(lang === 'ar' ? 'ml-3' : 'mr-3')} />
        {email || <Text value={translations.NO_EMAIL_PROVIDED} />}
      </span>
      <span className="flex items-center" style={{ direction }}>
        <PhoneCallIcon width="20px" height="20px" color="#234cfb" className={cx(lang === 'ar' ? 'ml-3' : 'mr-3')} />
        <a href={`tel:${phoneNumber}`} className={cx(lang === 'ar' && 'text-right', 'block text-primary-base text-sm')}>
          {phoneNumber}
        </a>
      </span>
    </div>
  );
};

OrderDetailsUserContact.propTypes = {
  order: PropTypes.shape({
    userData: PropTypes.shape({
      address: PropTypes.shape({
        area: PropTypes.shape({ cityTitleEn: PropTypes.string, cityTitleAr: PropTypes.string }),
      }),
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
};
export default OrderDetailsUserContact;
